.schoolsList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .sList {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  button {
    background-image: linear-gradient(to right, #2664eb, #588ef3);
    border-radius: 10px;
    color: white;
    border: none;
    outline: none;
    padding: 15px;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      scroll-behavior: 1.05;
      background-image: linear-gradient(to left, #2664eb, #588ef3);
    }
    margin: 20px;
  }
}
