.newUserOverlay {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newUserBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #334155;
  border-radius: 10px;
  font-weight: 400;
  margin: auto;

  input,
  select {
    width: 300px;
    margin: 20px;
    font-size: 20px;
    border-radius: 10px;
    outline: none;
    border: none;
    background-color: #475569;
    padding: 5px;
    text-align: center;
    color: white;

    &::placeholder {
      color: #598ef3;
    }
  }
  .buttonOptions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    button {
      background-image: linear-gradient(to right, #2664eb, #588ef3);
      border-radius: 10px;
      color: white;
      border: none;
      outline: none;
      padding: 15px;
      font-size: 20px;
      cursor: pointer;
      &:hover {
        scale: 1.05;
        background-image: linear-gradient(to left, #2664eb, #588ef3);
      }
    }
  }
}
