.profilHeader {
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 2px solid white;
  padding-bottom: 30px;
  padding-top: 30px;
  .imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    border: 5px solid white;
    border-radius: 50%;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 300ms;
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 100%;
    }
  }
  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    border: 5px solid white;
    margin-right: 30px;
  }
  p {
    font-size: 25px;
    color: white;
  }
}
.profilBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  margin-left: 5%;
  padding-top: 20px;

  .dugmence {
    background-image: linear-gradient(to right, #2664eb, #588ef3);
    border-radius: 10px;
    color: white;
    border: none;
    outline: none;
    padding: 15px;
    font-size: 20px;
    cursor: pointer;
    margin: 10px;
    margin-left: 0;
    &:hover {
      scale: 1.05;
      background-image: linear-gradient(to left, #2664eb, #588ef3);
    }
  }

  h3 {
    margin: 0;
    color: white;
  }
  .bodyStavka {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 10px;
    p {
      color: white;
      border-bottom: 1px solid white;
      margin: 0;
    }
    button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: forestgreen;
      transition: 300ms;
      outline: none;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
      font-size: 15px;
      color: white;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
        rgba(0, 0, 0, 0.22) 0px 10px 10px;
      &:hover {
        cursor: pointer;
        scale: 1.05;
      }
    }
  }
}
