.newUploadOverlay {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  .x {
    position: fixed;
    top: 15px;
    right: 15px;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 50px;
    color: white;
    transition: 150ms;

    &:hover {
      cursor: pointer;
      scale: 1.1;
    }
  }
}
.newUploadBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #334155;
  border-radius: 10px;
  font-weight: 400;
  margin: auto;
  max-width: 80vw;
  max-height: 60vh;
  min-width: 250px;
}
.newUploadBoxHeader {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid white;
  margin-bottom: 20px;
  padding-bottom: 10px;

  h2 {
    color: white;
    font-size: 30px;
    margin: 0;
    font-weight: 400;
  }
  p {
    margin: 0;
    font-size: 20px;
    color: white;
  }
}
.uploadDugme {
  background-image: linear-gradient(to right, #2664eb, #588ef3);
  border-radius: 10px;
  color: white;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  margin: 10px;
  transition: 300ms;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  &:hover {
    scale: 1.05;
    background-image: linear-gradient(to left, #2664eb, #588ef3);
  }
}
.newUploadBoxBody {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 10px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 15px;
  }
  &::webkit-scrollbar-thumb:hover {
    background-color: yellow;
  }
  p {
    color: white;
  }
  img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    border: 5px solid transparent;
    transition: 300ms;
    &:hover {
      scale: 1.05;
      cursor: pointer;
    }
    &.active {
      border: 5px solid greenyellow;
    }
  }
}
.iks {
  position: absolute;
  top: 5%;
  right: 5%;
}
.iksIkonica {
  font-size: 50px;
  color: white;
  &:hover {
    color: darken(white, 20%);
    cursor: pointer;
  }
}
