html {
  background-color: #1e293b;
}
.pocetnaJumbo {
  width: 80%;
  margin: auto;
  background-color: #1e293b;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-top: 120px;

  .jumboText {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    h1 {
      text-align: left;
      text-decoration: none;
      border: none;
      font-size: 45px;
      margin: 0;
      font-weight: 200;
    }
    ul {
      text-align: left;
      li {
        color: white;
        font-size: 25px;
      }
    }
    h2 {
      font-size: 35px;
      font-weight: 300;
      margin: 0;
    }
  }
  .jumboButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    button {
      padding: 10px 15px;
      border-radius: 10px;
      outline: none;
      border: none;
      color: white;
      background-image: linear-gradient(to right, #2664eb, #588ef3);
      font-size: 20px;
      transition: 300ms;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
        rgba(0, 0, 0, 0.22) 0px 10px 10px;
      margin-right: 15px;
      &:hover {
        scale: 1.05;
        cursor: pointer;
        // background-image: linear-gradient(to left, #2664eb, #588ef3);
      }
    }
  }
}
.pocetnaFunkcije {
  margin-top: 50px;
  h1 {
    font-weight: 500;
    margin-bottom: 20px;
  }
  .funkcijeh3 {
    color: white;
    text-align: left;
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
  }
  .funkcijeBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: auto;
  }
  .frontSide {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    padding: 10px;
    background-color: #2664eb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: white;
      margin: 0;
    }
    h3 {
      color: white;
      font-size: 30px;
    }
    cursor: pointer;
  }
  .backSide {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    padding: 10px;
    background-color: #334155;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ul {
      li {
        color: white;
      }
    }
  }
}
.pocetnaKontakt {
  margin-top: 50px;

  h1 {
    margin-bottom: 20px;
  }

  .box {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .boxText {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      h3 {
        color: white;
        font-size: 30px;
        margin: 0;
      }
      p {
        margin: 0;
        color: white;
        font-size: 30px;
        font-weight: 300;
        #linkic {
          text-decoration: none;
          color: white;

          &:hover {
            color: darken(white, 25%);
          }
        }
      }
    }

    .kontaktBox {
      width: 50%;
      padding: 15px;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
        rgba(0, 0, 0, 0.22) 0px 10px 10px;
      background-color: #334155;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      label {
        margin-bottom: 10px;
      }

      input {
        width: 90%;
        margin: 0;
        text-align: left;
        margin-bottom: 10px;
      }
      textarea {
        width: 90%;
        resize: none;
        font-size: 20px;
        border-radius: 10px;
        outline: none;
        border: none;
        background-color: #475569;
        padding: 5px;
        color: white;

        &::placeholder {
          color: #598ef3;
        }
      }
      button {
        padding: 10px 15px;
        margin-top: 15px;
        border-radius: 10px;
        outline: none;
        border: none;
        color: white;
        background-image: linear-gradient(to right, #2664eb, #588ef3);
        font-size: 20px;
        transition: 300ms;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
          rgba(0, 0, 0, 0.22) 0px 10px 10px;
        margin-right: 15px;
        &:hover {
          scale: 1.05;
          cursor: pointer;
          // background-image: linear-gradient(to left, #2664eb, #588ef3);
        }
      }
    }
  }
}
.pocetnaHeader {
  width: 100%;
  max-width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #2664eb;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  z-index: 999999999;
  .burger {
    display: none;
    background-color: transparent;
    outline: none;
    border: none;
  }
  .smallMenu {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    top: 80px;
    width: 100vw;
    height: calc(100vh - 80px);
    transition: 300ms;
    background-color: #2664eb;
    p {
      height: auto;
    }
  }

  img {
    height: 80px;
    object-fit: contain;
  }

  .middleLinks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    margin-right: 10px;
  }

  p {
    color: white;
    font-weight: 300;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
    transition: 150ms;
    cursor: pointer;
    &:hover {
      background-color: #2664eb;
    }
  }
}

h2 {
  font-size: 20px;
  font-weight: 300;
  color: white;
}
.welcome {
  width: 80%;
  margin: auto;
  //   margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  padding-bottom: 0px;
  // background-color: #334155;
  border-radius: 10px;
  // border: 1px solid #2664eb;
  .welcomeTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    .ikonicaOmotac {
      display: flex;
      justify-content: center;
      align-items: center;
      .backIkonica {
        color: white;
        font-size: 45px;
        margin-right: 10px;

        &:hover {
          cursor: pointer;
          color: darken(white, 20%);
        }
      }
    }
  }
  .welcomeButtons {
    button {
      margin-left: 15px;
    }
  }

  button {
    padding: 10px;
    border-radius: 10px;
    outline: none;
    border: none;
    color: white;
    background-image: linear-gradient(to right, #2664eb, #588ef3);
    font-size: 20px;
    transition: 300ms;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    &:hover {
      scale: 1.05;
      cursor: pointer;
      // background-image: linear-gradient(to left, #2664eb, #588ef3);
    }
  }
  h2 {
    font-size: 40px;
    font-weight: 600;
    color: white;
    margin: 0;
  }
  input {
    width: 200px;
    margin: 0;
    font-size: 20px;
    border-radius: 10px;
    outline: none;
    border: none;
    background-color: #334155;
    color: white;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    &::placeholder {
      color: white;
    }
  }
}
.optionsShortcuts {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  background-color: #334155;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  h1 {
    font-size: 25px;
    border: none !important;
  }
  &.manji {
    justify-content: space-evenly;
  }
  .shortcut {
    // width: 170px;
    // height: 170px;
    border-radius: 50%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    transition: 300ms;
    margin: 20px;

    .ikonicaWrap {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-image: linear-gradient(to right, #2664eb, #588ef3);
      box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
        rgba(0, 0, 0, 0.22) 0px 10px 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.correct {
        background-image: linear-gradient(to right, #3ca70b, #99e974);
      }
    }
    h3 {
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      margin: 0;
      margin-top: 5px;
      text-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
        rgba(0, 0, 0, 0.22) 0px 10px 10px;
    }

    &:hover {
      scale: 1.05;
      cursor: pointer;
      // background-image: linear-gradient(to left, #2664eb, #588ef3);
    }
  }
}
.opcijeKontejner {
  width: 80%;
  height: auto;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // flex-wrap: wrap;
  // background-color: red;
  .opcije {
    background-color: #334155;
    border-radius: 10px;
    padding: 10px;
    align-self: stretch;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    &.Levo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 20%;
      p {
        color: white;
        text-align: left;
        width: 100%;
        font-size: 20px;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid white;
      }
      .opcijeLevoDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .opcijeLevoHeader {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid white;
        .opcijeLevoHeaderImage {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 50px;
            height: 50px;
            border: 2px solid white;
            border-radius: 50%;
            object-fit: contain;
          }
        }
        .opcijeLevoHeaderTekst {
          color: white;
          font-size: 15px;
          margin-left: 10px;
        }
      }
      .opcijeLevoBody {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-top: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        .stavka {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 5px;

          h4 {
            color: white;
            font-size: 16px;
            margin: 0;
            text-align: left;
          }
          p {
            color: white;
            font-size: 16px;
            text-decoration: none;
            border: none;
          }
        }
      }

      button {
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 10px;
        outline: none;
        border: none;
        color: white;
        background-image: linear-gradient(to right, #2664eb, #588ef3);
        font-size: 15px;
        transition: 300ms;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
          rgba(0, 0, 0, 0.22) 0px 10px 10px;
        &:hover {
          scale: 1.05;
          cursor: pointer;
          // background-image: linear-gradient(to left, #2664eb, #588ef3);
        }
      }
    }
    &.Sredina {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      flex-wrap: wrap;
      .opcijeSredinaHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid white;
        p {
          transition: 300ms;
          &:hover {
            cursor: pointer;
            scale: 1.05;
          }
        }
        .optionsHeader {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .okvirOpcije {
            width: 40px;
            height: 40px;
            margin-left: 10px;
            background-image: linear-gradient(to right, #2664eb, #588ef3);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 150ms;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
              rgba(0, 0, 0, 0.22) 0px 10px 10px;
            &:hover {
              scale: 1.2;
              cursor: pointer;
            }
          }

          input {
            width: 100px;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
              rgba(0, 0, 0, 0.22) 0px 10px 10px;
          }
        }
      }
      .opcijeSredinaBody {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-height: 350px;
        overflow-y: scroll;
        padding-bottom: 20px;
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.75);
          border-radius: 15px;
        }
        &::webkit-scrollbar-thumb:hover {
          background-color: yellow;
        }
        .korisnik {
          width: 90%;
          margin-top: 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 10px;
          background-color: #5f799f;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
            rgba(0, 0, 0, 0.22) 0px 10px 10px;

          .korisnikInfo {
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            img {
              width: 50px;
              height: 50px;
              object-fit: cover;
              border-radius: 50%;
              aspect-ratio: 1/1;
              background-color: #5f799f;
              box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
                rgba(0, 0, 0, 0.22) 0px 10px 10px;
              margin-right: 10px;
            }
            h3 {
              margin: 0;
              color: white;
            }
            p {
              margin: 0;
              color: white;
            }
          }

          .opcijeKorisnik {
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .okvirOpcije {
              width: 40px;
              height: 40px;
              margin-left: 10px;
              background-image: linear-gradient(to right, #2664eb, #588ef3);
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: 150ms;
              box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
                rgba(0, 0, 0, 0.22) 0px 10px 10px;
              &:hover {
                scale: 1.2;
                cursor: pointer;
              }
            }
          }
        }
        .termin {
          margin-top: 20px;
          width: 90%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 10px;
          background-color: #5f799f;
          // border: 3px solid white;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
            rgba(0, 0, 0, 0.22) 0px 10px 10px;
          .terminInfo {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }
          .terminOptions {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .okvirOpcije {
              width: 40px;
              height: 40px;
              margin-left: 10px;
              background-image: linear-gradient(to right, #2664eb, #588ef3);
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: 150ms;
              box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
                rgba(0, 0, 0, 0.22) 0px 10px 10px;
              &:hover {
                scale: 1.2;
                cursor: pointer;
              }
            }
          }
          h3 {
            color: white;
            font-size: 20px;
            font-weight: 500;
            margin: 0;
          }

          &.free {
            background-image: linear-gradient(to right, #3ca70b, #99e974);
          }
          &.reserved {
            background-color: darken(red, 20%);
          }
        }
      }
      h3 {
        color: white;
      }
    }

    &.Desno {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 20%;
      flex-wrap: wrap;
      .opcijeDesnoHeader {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid white;
        font-size: 15px;

        h3 {
          color: white;
          text-align: center;
        }
      }
      .opcijeDesnoBody {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        // padding-top: 10px;
        padding-bottom: 20px;

        max-height: 350px;
        overflow-y: scroll;
        padding-bottom: 20px;

        h3 {
          color: white;
        }
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.75);
          border-radius: 15px;
        }
        &::webkit-scrollbar-thumb:hover {
          background-color: yellow;
        }
        .stavka {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 5px;

          h4 {
            color: white;
            font-size: 16px;
            margin: 0;
            text-align: left;
          }
          p {
            color: white;
            font-size: 16px;
            text-decoration: none;
            border: none;
            margin: 0;
          }

          &.clickable {
            p {
              &:hover {
                cursor: pointer;
              }
            }
          }
        }

        .korisnik {
          width: 90%;
          margin-top: 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 10px;
          background-color: #5f799f;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
            rgba(0, 0, 0, 0.22) 0px 10px 10px;
          .korisnikInfo {
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            img {
              width: 50px;
              height: 50px;
              object-fit: cover;
              border-radius: 50%;
              background-color: #5f799f;
              aspect-ratio: 1/1;
              box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
                rgba(0, 0, 0, 0.22) 0px 10px 10px;
            }
            h3 {
              margin-left: 10px;
              color: white;
            }
          }
          .opcijeKorisnik {
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .okvirOpcije {
              width: 40px;
              height: 40px;
              margin-left: 10px;
              background-image: linear-gradient(to right, #2664eb, #588ef3);
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: 150ms;
              box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
                rgba(0, 0, 0, 0.22) 0px 10px 10px;
              &:hover {
                scale: 1.2;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .opcija {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
      transition: 300ms;
      margin: 20px;
      .ikonicaWrap {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        background-image: linear-gradient(to right, #2664eb, #588ef3);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      h3 {
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        margin: 0;
      }

      &:hover {
        scale: 1.05;
        cursor: pointer;
        // background-image: linear-gradient(to left, #2664eb, #588ef3);
      }
    }
  }
}
.optionsContainer {
  width: 80%;
  margin: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.option {
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #2664eb, #588ef3);
  border-radius: 10px;
  color: white;
  transition: 300ms;
  margin: 20px;
  h3 {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }

  &:hover {
    scale: 1.05;
    cursor: pointer;
    background-image: linear-gradient(to left, #2664eb, #588ef3);
  }
}
.resultBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
  width: 80%;
  margin: auto;
  h1 {
    margin: 0;
    border: none;
  }
  h4 {
    text-align: center;
  }
}
.answersBox {
  height: 80vh;
  width: 80%;
  margin: auto;
  position: relative;
  .timer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #5f799f;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    font-size: 50px;
    color: white;
  }
  .answersBoxRow {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .answersBoxColumn {
    width: 45%;
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    border-radius: 10px;
    transition: 150ms;
    background-image: linear-gradient(to left, #2664eb, #588ef3);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    cursor: pointer;
    h1 {
      color: white;
      font-size: 50px;
      margin: 0;
      border-bottom: none;
    }

    &:hover {
      scale: 1.05;
    }
  }
}
.list {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  position: relative;

  .timer {
    position: absolute;
    right: -25px;
    top: -25px;
    background-color: #5f799f;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    font-size: 50px;
    color: white;
  }
  .listPieceVertical {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #334155;
    padding: 15px 10px 15px 10px;
    border-radius: 10px;

    h2 {
      font-size: 30px;
      font-weight: 700;
      margin: 0;
    }
    p {
      margin: 0;
      color: white;
      font-size: 15px;
    }
    .listPieceRow {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .listPieceRowQuestions {
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        text {
          fill: white !important;
        }

        p {
          font-size: 20px;
          color: white;
          border-radius: 10px;
          border: 2px solid white;
          padding: 10px;
          width: 90%;
        }

        textarea {
          resize: none;
          max-width: 90%;
          min-width: 90%;
          margin-bottom: 20px;
          height: 50px;
          font-size: 20px;
          border-radius: 10px;
          outline: none;
          border: 2px solid white;
          background-color: #334155;
          color: white;
          padding: 10px;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
            rgba(0, 0, 0, 0.22) 0px 10px 10px;

          &.correct {
            background-image: linear-gradient(to right, #3ca70b, #99e974);
            // border: 2px solid #3ca70b;
          }
          &.incorrect {
            background-image: linear-gradient(to right, red, lighten(red, 15%));
            // border: 2px solid red;
          }
          &::placeholder {
            color: white;
          }
        }
        label {
          margin-left: 5px;
          font-size: 20px;
          font-weight: 600;
        }
      }
      .listPieceRowImage {
        height: 100%;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        overflow-y: scroll;
        max-height: 800px;

        .ocenjenOdgovor {
          width: 80%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          border-radius: 10px;
          background-color: #5f799f;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
            rgba(0, 0, 0, 0.22) 0px 10px 10px;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 10px;
          padding-right: 10px;
          p {
            font-size: 25px;
            font-weight: 500;
          }
        }

        button {
          margin-bottom: 20px;
          padding: 10px 20px 10px 20px;
          border-radius: 10px;
          outline: none;
          border: none;
          color: white;
          background-image: linear-gradient(to right, #2664eb, #588ef3);
          font-size: 25px;
          font-weight: 600;
          transition: 300ms;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
            rgba(0, 0, 0, 0.22) 0px 10px 10px;
          &:hover {
            scale: 1.15;
            cursor: pointer;
            // background-image: linear-gradient(to left, #2664eb, #588ef3);
          }
        }

        img {
          width: 100%;
          max-height: 500px;
          object-fit: contain;
        }
        .listPieceRowImageButtons {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          align-items: center;

          button {
            padding: 10px 20px 10px 20px;
            border-radius: 10px;
            outline: none;
            border: none;
            color: white;
            background-image: linear-gradient(to right, #2664eb, #588ef3);
            font-size: 25px;
            font-weight: 600;
            transition: 300ms;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
              rgba(0, 0, 0, 0.22) 0px 10px 10px;
            &.correct {
              background-image: linear-gradient(to right, #3ca70b, #99e974);
            }
            &.incorrect {
              background-image: linear-gradient(
                to right,
                red,
                lighten(red, 15%)
              );
            }
            &:hover {
              scale: 1.15;
              cursor: pointer;
              // background-image: linear-gradient(to left, #2664eb, #588ef3);
            }
          }
        }
      }
    }
    .listPieceOptions {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 40px;
      button {
        padding: 10px 20px 10px 20px;
        border-radius: 10px;
        outline: none;
        border: none;
        color: white;
        background-image: linear-gradient(to right, #2664eb, #588ef3);
        font-size: 25px;
        font-weight: 600;
        transition: 300ms;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
          rgba(0, 0, 0, 0.22) 0px 10px 10px;
        &.correct {
          background-image: linear-gradient(to right, #3ca70b, #99e974);
        }
        &:hover {
          scale: 1.15;
          cursor: pointer;
          // background-image: linear-gradient(to left, #2664eb, #588ef3);
        }
      }
    }
  }

  .listPiece {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #334155;
    padding: 15px 10px 15px 10px;
    border-radius: 10px;

    .pieceInfo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 3px solid white;
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
          rgba(0, 0, 0, 0.22) 0px 10px 10px;
        margin-right: 20px;
      }
      .pieceInfoText {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        h3 {
          color: white;
          font-size: 25px;
          margin: 0;
        }
        h4 {
          color: red;
          font-size: 15px;
          margin: 0;
        }
        p {
          margin: 0;
          color: white;
          font-size: 15px;
        }
      }
    }
    .pieceOptions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      h3 {
        color: white;
        font-size: 25px;
        margin: 0;
      }

      .pieceOption {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
        transition: 300ms;
        p {
          color: white;
          margin: 0;
          margin-top: 5px;
        }
        .pieceOptionIcon {
          width: 75px;
          height: 75px;
          border-radius: 50%;
          background-color: blue;
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: linear-gradient(to right, #2664eb, #588ef3);
          box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
            rgba(0, 0, 0, 0.22) 0px 10px 10px;
        }
        &:hover {
          cursor: pointer;
          scale: 1.1;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .pocetnaHeader {
    .middleLinks {
      display: none;
    }
    .burger {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .pocetnaJumbo {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .jumboText {
      align-items: center;
      h1 {
        font-size: 35px;
        text-align: center;
      }
      h2 {
        font-size: 30px;
        text-align: center;
      }
    }
  }
  .pocetnaFunkcije {
    .funkcijeBox {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .react-card-flip {
        margin-bottom: 15px;
      }
    }
  }
  .pocetnaKontakt {
    .box {
      flex-direction: column;
      .kontaktBox {
        width: 100%;
      }
      .boxText {
        margin-bottom: 15px;
        h3 {
          font-size: 20px;
        }
        p {
          font-size: 20px;
        }
      }
    }
  }
  .welcome {
    justify-content: center;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: wrap;
    padding-left: 0px;
    padding-right: 0px;
    .welcomeTitle {
      max-width: 100%;
    }
    h2 {
      font-size: 30px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  .optionsShortcuts {
    width: 80%;
    padding: 10px;
    .shortcut {
      margin: 5px;
      .ikonicaWrap {
        width: 60px;
        height: 60px;
        svg {
          height: 30px;
          width: 30px;
        }
      }
      h3 {
        font-size: 15px;
      }
    }
  }
  .opcijeKontejner {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .opcije {
      width: 100% !important;
      margin-bottom: 20px;
      align-self: center;
    }
  }

  .list {
    width: 90%;
    .listPiece {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: hidden;

      .pieceInfo {
        overflow: hidden;
      }

      .pieceOptions {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .pieceOption {
          margin: 5px;
          text-align: center;
          p {
            font-size: 15px;
          }
          .pieceOptionIcon {
            height: 60px;
            width: 60px;
            svg {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
    .listPieceVertical {
      .listPieceRow {
        flex-direction: column-reverse;

        .listPieceRowImage {
          width: 100%;
          .ocenjenOdgovor {
            h2 {
              font-size: 20px;
            }
            p {
              font-size: 20px;
            }
          }
        }
        .listPieceRowQuestions {
          width: 100%;
          textarea {
            height: auto;
          }
        }
      }
    }
  }
  .kalendarHeader {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .kalendar {
    grid-template-columns: 1fr !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .pocetnaJumbo {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .jumboText {
      align-items: center;
      h1 {
        text-align: center;
      }
      h2 {
        text-align: center;
      }
    }
  }
  .pocetnaFunkcije {
    .funkcijeBox {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .react-card-flip {
        margin-bottom: 15px;
      }
    }
  }
  .pocetnaKontakt {
    .box {
      flex-direction: column;
      .boxText {
        margin-bottom: 15px;
      }
    }
  }
  .welcome {
    justify-content: space-between;
    align-items: center;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: wrap;
    padding-left: 0px;
    padding-right: 0px;
    .welcomeTitle {
      max-width: 100%;
    }
    h2 {
      font-size: 30px;
    }
  }
  .optionsShortcuts {
    width: 80%;
    padding: 10px;
    .shortcut {
      margin: 5px;
      .ikonicaWrap {
        width: 75px;
        height: 75px;
        svg {
          height: 45px;
          width: 45px;
        }
      }
      h3 {
        font-size: 17px;
      }
    }
  }
  .opcijeKontejner {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .opcije {
      width: 100% !important;
      margin-bottom: 20px;
      align-self: center;
    }
  }

  .list {
    // width: 100%;
    .listPiece {
      overflow: hidden;
      // width: 100%;

      .pieceInfo {
        overflow: hidden;
      }

      .pieceOptions {
        flex-wrap: wrap;
        justify-content: space-evenly;
        .pieceOption {
          margin: 5px;
          text-align: center;
          p {
            font-size: 15px;
          }
          .pieceOptionIcon {
            height: 60px;
            width: 60px;
            svg {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
    .listPieceVertical {
      .listPieceRow {
        flex-direction: column-reverse;

        .listPieceRowImage {
          width: 100%;
          .ocenjenOdgovor {
            h2 {
              font-size: 20px;
            }
            p {
              font-size: 20px;
            }
          }
        }
        .listPieceRowQuestions {
          width: 100%;
          textarea {
            height: auto;
          }
        }
      }
    }
  }
  .kalendarHeader {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .kalendar {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
  }
}
