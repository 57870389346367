.navigation {
  width: 100vw;
  height: 80px;
  z-index: 999999;
  // width: 80px;
  // height: 100vh;
  display: flex;
  flex-direction: row;
  // flex-direction: column;
  justify-content: center;
  // justify-content: flex-start;
  // padding-top: 50px;
  align-items: center;

  position: fixed;
  bottom: 0;
  // top: 0;
  left: 0;
  background-color: #334155;
  // border-top: 1px solid #2563eb;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -14px 14px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.iconBox {
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  // padding: 10px;
  // margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p {
    margin: 0;
    color: #fff;
    font-size: 14px;
  }
  &:hover {
    background-color: #2563eb;
    :first-child {
      color: #fff;
    }

    :last-child {
      color: #fff;
    }
  }
}
