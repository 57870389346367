@keyframes upozorenje {
  0% {
    top: -100%;
  }
  25% {
    top: 10px;
  }
  75% {
    top: 10px;
  }
  100% {
    top: -100%;
  }
}
.alertBox {
  position: fixed;
  top: -100%;
  left: 50%;
  width: 300px;
  transform: translateX(-50%);
  background-color: #334155;
  padding: 15px;
  border-radius: 10px;
  animation-name: upozorenje;
  animation-duration: 1.5s;
  z-index: 999999999999999999999999999999999999999999999999;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  p {
    color: white;
    font-size: 25px;
    font-weight: 500;
    text-decoration: none;
    border: none;
    margin: auto;
    text-align: center;
    text-overflow: ellipsis;
  }
}
.newSTOverlay {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;

  .x {
    position: fixed;
    top: 15px;
    right: 15px;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 50px;
    color: white;
    transition: 150ms;

    &:hover {
      cursor: pointer;
      scale: 1.1;
    }
  }
}
.newSTBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #334155;
  border-radius: 10px;
  font-weight: 400;
  margin: auto;
  position: relative;

  p {
    color: white;
    margin: 0;
  }
  .inputBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .eyeIkonica {
      color: white;

      &:hover {
        cursor: pointer;
        color: darken(white, 20%);
      }
    }
  }

  input,
  select,
  textarea {
    width: 100%;
    max-width: 300px;
    margin: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    border-radius: 10px;
    outline: none;
    border: none;
    background-color: #475569;
    padding: 5px;
    text-align: center;
    color: white;

    &::placeholder {
      color: #598ef4;
    }
  }

  select {
    width: 100%;
  }

  .izborDatumaMUI {
    width: 100%;
    margin-bottom: 15px;
    color: white;
    justify-content: center;
    #selectGrupe {
      color: white;
    }
    &:hover {
      fieldset {
        border-color: #1976d2;
      }
    }
    fieldset {
      border-color: white;
    }
    label {
      color: white;
    }
    svg {
      fill: white;
    }
  }

  .dugme {
    background-image: linear-gradient(to right, #2664eb, #588ef3);
    border-radius: 10px;
    color: white;
    border: none;
    outline: none;
    padding: 15px;
    font-size: 20px;
    cursor: pointer;
    margin: 10px;
    &:hover {
      scale: 1.05;
      background-image: linear-gradient(to left, #2664eb, #588ef3);
    }
  }
}
.newSTBoxHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid white;
  margin-bottom: 20px;
  padding-bottom: 10px;

  h2 {
    color: white;
    font-size: 25px;
    margin: 0;
  }
  p {
    margin: 0;
    font-size: 20px;
    color: white;
  }
}
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(100%);
}
input,
select {
  width: 300px;
  margin: 10px;
  font-size: 20px;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: #475569;
  padding: 5px;
  text-align: center;
  color: white;

  &::placeholder {
    color: #598ef4;
  }
}
label {
  color: white;
  font-size: 20px;
  font-weight: 300;
}
.buttonOptions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
  button {
    background-image: linear-gradient(to right, #2664eb, #588ef3);
    border-radius: 10px;
    color: white;
    border: none;
    outline: none;
    padding: 15px;
    font-size: 20px;
    cursor: pointer;
    margin: 10px;
    &:hover {
      scale: 1.05;
      background-image: linear-gradient(to left, #2664eb, #588ef3);
    }
  }
}

.vreme {
  width: 200px;
  margin: 5px;
}

.newSTList {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;

  h3 {
    color: white;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 15px;
  }
  &::webkit-scrollbar-thumb:hover {
    background-color: yellow;
  }

  .osvezi {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
    outline: none;
    border: none;
    color: white;
    background-image: linear-gradient(to right, #2664eb, #588ef3);
    font-size: 20px;
    transition: 300ms;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    &:hover {
      scale: 1.05;
      cursor: pointer;
      // background-image: linear-gradient(to left, #2664eb, #588ef3);
    }
  }

  .studentBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #5f799f;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 200px;
    .studentBoxInfo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-right: 20px;

      .studentBoxImage {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        img {
          width: 75px;
          height: 75px;
          border: 2px solid white;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }
      }
      .studentBoxInfoText {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;

        h2 {
          font-size: 20px;
          color: white;
          margin: 0;
          text-align: left;
          font-weight: 600;
        }
        p {
          font-size: 15px;
          color: white;
          margin: 0;
          text-align: left;
        }
      }
    }

    .studentBoxOptions {
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;

      button {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
          rgba(0, 0, 0, 0.22) 0px 10px 10px;
        background-image: linear-gradient(to right, #2664eb, #588ef3);
        color: white;
        border: none;
        outline: none;
        padding: 15px;
        font-size: 20px;
        cursor: pointer;
        margin: 10px;
        height: 70px;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        transition: 150ms;
        &:hover {
          scale: 1.05;
        }
      }
    }
  }
}
