.loginPage {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    color: grey;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  h2 {
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0px;

    &:hover {
      cursor: pointer;
      color: darken(white, 20%);
    }
  }

  img {
    width: 330px;
    max-width: 100%;
    margin-bottom: 10px;
  }
}
.izborDatumaMUI {
  width: 100%;
  color: white;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  &:hover fieldset {
    border-color: #588ef3 !important;
  }
  label {
    margin-top: 10px;
  }
  #selectGrupe {
    color: white;
  }
  &:hover {
    fieldset {
      border-color: #1976d2;
    }
  }
  fieldset {
    border-color: white;
  }
  label {
    color: white;
  }
  svg {
    fill: white;
  }
}
.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  flex-wrap: wrap;
  background-color: #334155;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  h2 {
    width: 100%;
    text-align: center;
    font-weight: 400;
    margin: auto;
  }

  input {
    width: 250px;
    margin: 10px;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 17px;
    border-radius: 10px;
    outline: none;
    border: none;
    background-color: #475569;
    padding: 5px;
    text-align: center;
    color: white;

    &::placeholder {
      color: #598ef3;
    }
  }
}
.loginOptions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  button {
    background-image: linear-gradient(to right, #2664eb, #588ef3);
    border-radius: 10px;
    color: white;
    border: none;
    outline: none;
    padding: 15px;
    font-size: 20px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    &:hover {
      scale: 1.05;
      background-image: linear-gradient(to left, #2664eb, #588ef3);
    }
  }
}
