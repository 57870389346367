.kalendarHeader {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  margin: auto;

  button {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  h2 {
    font-size: 40px;
    color: white;
    font-weight: 600;
    border-bottom: 2px solid white;
    margin: 0;
    width: 100%;
    text-align: center;
    cursor: default !important;
  }
}
.kalendar {
  width: 95%;
  margin: auto;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  justify-items: center;
  // row-gap: 10px;
  padding: 10px;
  padding-top: 20px;
  overflow-x: hidden;
}
.kockaKalendara {
  width: 100%;
  min-height: 300px;
  border: 1px solid white;
  // border-radius: 10px;
  transition: 150ms;
  &:hover {
    background-color: lighten(#1e293b, 20%);
    cursor: pointer;
    scale: 1.05;
  }
  &:nth-child(even) .imeKocke {
    background-image: linear-gradient(to left, #145cf7, #6390e4);
  }
  &:nth-child(odd) .imeKocke {
    background-image: linear-gradient(to right, #145cf7, #6390e4);
  }
  .imeKocke {
    width: 100%;
    border-bottom: 2px solid white;
    background-image: linear-gradient(to right, #145cf7, #6390e4);
    padding-top: 10px;
    padding-bottom: 10px;
    // border-top-left-radius: 8px;
    // border-top-right-radius: 8px;
    p {
      color: white;
      text-align: center;
      margin: 0;
    }
  }
  .bodyKocke {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    max-width: 100%;

    .terminKocke {
      // width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 5px;
      margin-bottom: 5px;
      border: 1px solid rgba(255, 255, 255, 0.7);

      p {
        color: white;
        font-size: 15px;
        margin: 0;
      }

      &.slobodan {
        background-color: green;
      }
      &.zauzet {
        background-color: red;
      }
      &.gotov {
        background-color: #5f799f;
      }
    }
  }
}
