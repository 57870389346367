@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  font-family: "Poppins" !important;
}
.app {
  width: 100%;
  min-height: 100vh;
  //   overflow: hidden;
  background-color: #1e293b;
  padding-bottom: 80px;
  // padding-top: 80px;
}

h1 {
  color: #ffff;
  margin: 0;
  width: 80%;
  margin: auto;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #94a3b8;
  font-size: 45px;
  font-weight: 300;
}
