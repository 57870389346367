.kalendarHeader {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  margin: auto;

  .izborDatumaMUI {
    // display: none;
    z-index: -1;
    opacity: 0;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
  }

  button {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  h2 {
    z-index: 10;
    font-size: 40px;
    color: white;
    font-weight: 600;
    border-bottom: 2px solid white;
    margin: 0;
    width: 100%;
    text-align: center;
    cursor: pointer;
  }
}
.kalendarBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 80px;
  button {
    margin-top: 20px;
    background-image: linear-gradient(to right, #2664eb, #588ef3);
    outline: none;
    border: 0;
    padding: 15px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    transition: 300ms;
    &:hover {
      scale: 1.05;
      cursor: pointer;
      background-image: linear-gradient(to left, #2664eb, #588ef3);
    }
    p {
      margin: 0;
      color: white;
      font-size: 20px;
    }
  }

  .termin {
    margin-top: 20px;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    border: 3px solid white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;

    &.free {
      background-image: linear-gradient(to right, #3ca70b, #99e974);
    }
    &.closed {
      background-color: darken(red, 20%);
    }
    &.done {
      background-color: #5f799f;
    }
    .terminInfo {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      h3 {
        color: white;
        font-size: 25px;
        font-weight: 500;
        margin: 0;
      }

      p {
        color: white;
        font-size: 20px;
        font-weight: 400;
        margin: 0;
      }
    }
    .terminOptions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .ikonicaWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
        transition: 150ms;
        &:hover {
          cursor: pointer;
          scale: 1.1;
        }
        p {
          color: white;
          margin: 0;
          text-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
            rgba(0, 0, 0, 0.22) 0px 10px 10px;
        }
        .ikonicaKrug {
          padding: 10px;
          //   background-image: linear-gradient(to right, #2664eb, #87aaec);
          background-color: #2664eb;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
            rgba(0, 0, 0, 0.22) 0px 10px 10px;

          aspect-ratio: 1/1;
        }
        .trashIkonica {
          color: white;
          font-size: 30px;
          transition: 300ms;
        }
      }
    }
  }
}
